.status-light {
    margin-left: -12px;
    font-size: var(--spectrum-global-dimension-size-150);
    font-style: italic;
    color: #464646;
    padding: 0;
}

.status-option--italic {
    font-style: italic;
    font-size: var(--spectrum-global-dimension-size-150);
}

.status-light--change::before {
    margin-top: var(--spectrum-global-dimension-size-150);
    position: absolute;
    z-index: 1;
    margin-left: var(--spectrum-global-dimension-size-125);
}

.task-item {
    border-bottom: var(--spectrum-global-dimension-size-10) solid var(--spectrum-gray-200);
    padding-top: var(--spectrum-global-dimension-size-150);
    line-height: var(--spectrum-global-dimension-size-275);
    padding-bottom: var(--spectrum-global-dimension-size-150);
}

.task-item:first-child {
    padding-top: 0;
}

.task-item:hover {
    cursor: pointer;
}

.task-label {
    font-size: var(--spectrum-global-dimension-size-150);
    color: #505050;
    font-weight: 500;
}

.task-name {
    align-self: center;
    overflow: hidden;
    color: #292929;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Adobe Clean", sans-serif;
    font-size: var(--spectrum-global-dimension-size-175);
    font-style: normal;
    font-weight: 700;
    line-height: var(--spectrum-global-dimension-size-250);
}
