.status-picker {
    /* avoid width override by spectrum-Field */
    width: 100% !important;
}

.status-picker button {
    --status-picker-border-width: 1px;
    border: var(--status-picker-border-width) solid transparent;
    padding-left: 0;
    justify-content: flex-start;
}

.status-picker svg {
    display: none;
}

.status-picker:hover {
    cursor: pointer;
}

.status-picker button:hover {
    border: var(--status-picker-border-width) solid
        var(--spectrum-fieldbutton-border-color, var(--spectrum-alias-border-color));
}

.status-picker button:hover svg {
    display: block;
    margin-left: auto;
}

.status-light--custom {
    content: "";
    inline-size: var(--spectrum-statuslight-dot-size, var(--spectrum-global-dimension-size-100));
    block-size: var(--spectrum-statuslight-dot-size, var(--spectrum-global-dimension-size-100));
    margin: calc(var(--spectrum-global-dimension-size-65) + 1px)
        var(--spectrum-statuslight-text-gap, var(--spectrum-global-dimension-size-150))
        calc(var(--spectrum-global-dimension-size-65) - 1px)
        var(--spectrum-statuslight-text-gap, var(--spectrum-global-dimension-size-150));
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-block;
}

.spectrum-Dropdown-popover [role="option"] {
    height: 100%;
}

.spectrum-Dropdown-popover [role="option"] .status-light--custom {
    margin-left: var(--spectrum-global-dimension-size-75);
}

.spectrum-Dropdown-popover [role="option"] .status-option {
    padding-left: var(--spectrum-global-dimension-size-150);
}

.spectrum-Dropdown-popover [role="option"] svg {
    padding-top: 0px;
}

.task-details-section {
    margin: var(--spectrum-global-dimension-size-150) 0;
    line-height: var(--spectrum-global-dimension-size-250);
}
