.panel-top-action {
    height: var(--spectrum-global-dimension-size-400, var(--spectrum-alias-size-400));
    margin: 0px;
    border-bottom: var(--spectrum-global-dimension-size-10) solid var(--spectrum-gray-200);
    align-items: center;
    display: flex;
}

.panel-top-action button {
    border: 0;
}

.panel-top-action button svg {
    /* align vertically chevron svg icon with the text/labels below */
    margin-left: -6px;
    /* avoid spectrum-ActionButton / spectrum-Icon override */
    padding-inline-start: 0 !important;
}

.back-to-project-button {
    cursor: pointer;
}
