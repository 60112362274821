/* [class*="_spectrum-overlay"] {
    overflow: scroll;
} */

.label {
    font-size: var(--spectrum-global-dimension-size-150);
    color: #505050;
}

.due-date-label {
    font-size: var(--spectrum-global-dimension-size-150);
    color: #505050;
    font-weight: 400;
    margin-right: var(--spectrum-global-dimension-size-150);
}

.date-label {
    color: var(
        --Alias-content-neutral-subdued-default,
        var(--Alias-content-neutral-subdued-default, #464646)
    );
    font-family: "Adobe Clean", sans-serif;
    font-size: var(--spectrum-global-dimension-size-150);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.date-planned-completed {
    color: var(
        --Alias-content-neutral-subdued-hover,
        var(--Alias-content-neutral-subdued-hover, #222)
    );
    font-family: "Adobe Clean", sans-serif;
    font-size: var(--spectrum-global-dimension-size-175);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: var(--spectrum-global-dimension-size-150);
}

.task-management-wrapper > section {
    border-bottom: var(--spectrum-global-dimension-size-10) solid var(--spectrum-gray-200);
    background-color: var(--spectrum-global-color-gray-75);
    padding-top: var(--spectrum-global-dimension-size-100, var(--spectrum-alias-size-100));
    padding-bottom: var(--spectrum-global-dimension-size-100, var(--spectrum-alias-size-100));
}

/* In order to have horizontal separator aka bottom border across the whole widget
   the parent section containers shouldn't have left/right paddings
   instead add paddings to the child containers */
.task-management-wrapper > section > section,
.task-management-wrapper > section > div,
.task-management-wrapper > section > h4 {
    padding-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    padding-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
}

.task-management-wrapper > section:last-child {
    border-bottom: 0;
}
