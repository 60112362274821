.owner-avatar {
    height: 42px;
    width: 42px;
    margin-right: 5px;
    border-radius: 50%;
}

.owner-full-name {
    color: var(--v-600-light-gray-900, #000);
    /* Body/Strong/XS */
    font-family: "Adobe Clean", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px; /* 125% */
}

.owner-title {
    color: #4b4b4b;
    font-family: "Adobe Clean", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 210% */
}
